import React from 'react';
import '../components/styles/jobAdvertisement.css'

function JobAdvertisement() {
    return (
        <div className="d-flex justify-content-center align-items-center main">
            <img src="assets/jobAdvertisement.jpg" alt="Job Advertisement"/>
        </div>
    );
}

export default JobAdvertisement;