import React from 'react';
import TeamCard from "../components/TeamCard";
import '../components/styles/About.css'

function About() {
    return (
        <div className="background-main">
            <h1 className="text-center heading-about">Our Team</h1>

            <div className="container grid">
                <TeamCard image={"assets/gabriel.png"} name={"Gabriel Lückl"} contact={"luega@doxedu.at"}
                          title={"CEO & CO-FOUNDER"}/>
                <TeamCard image={"assets/julian.jpg"} name={"Julian Monschein"} contact={"monju@doxedu.at"}
                          title={"CTO & CO-FOUNDER"}/>
                <TeamCard image={"assets/jonas.jpg"} name={"Jonas Förstner-Heschl"} contact={"foejo@doxedu.at"}
                          title={"CFO & CO-FOUNDER"}/>
            </div>
        </div>
    );
}

export default About;