import React from 'react';
import {Outlet} from "react-router-dom";
import Navbar from "../components/Navbar";

function Layout() {
    return (
        <div style={{background: "#0D1117"}}>
            <Navbar/>

            <main>
                <Outlet/>
            </main>
        </div>
    );
}

export default Layout;