import React from 'react';
import PropTypes from 'prop-types';
import './styles/TeamCard.css'

TeamCard.propTypes = {

    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

function TeamCard({image, name, contact, title}:any) {
    return (
        <div className="team-card">
            <img className="profile-pic" src={image} alt="image not found"/>

            <div className="profile-text">
                <div>{name}</div>
                <div>{title}</div>
                <div>Contact: {contact}</div>
            </div>

        </div>
    );
}

export default TeamCard;