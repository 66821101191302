import React from 'react';
import SplitGrid from "../components/SplitGrid";
import '../components/styles/Home.css'

function Home() {
    return (
        <div>
            <div className="background-image marginBack">
                <h2 className="heading"><img style={{width: 200}}className="logo" src="assets/dox.png"/></h2>
                <h4 className="padding-left">Experience a new way of learning</h4>
                <p className="padding-left bottom-distance">Discover a social media platform dedicated to learning and growing together. <br/>Connect, share, and
                    expand
                    your knowledge with DOX.</p>
            </div>

            <div className="background-main">
                <div className="container mt-4">
                    <SplitGrid
                        heading="Learn Together"
                        text="Join a community where curiosity thrives. Engage in meaningful discussions, exchange ideas,
                            and collaborate on learning projects with DOX."
                        image="assets/together.jpg"
                        isSwapped={false}/>

                    <SplitGrid
                        heading="Share your Expertise"
                        text="Everyone has something to teach. Share your insights, tips, and expertise with a like-minded
                                community eager to learn from you on DOX."
                        image="assets/shareExperience.jpg"
                        isSwapped={true}/>

                    <SplitGrid
                        heading="Explore New Topics"
                        text="From science to the arts, find and explore a wide range of topics that interest you. With
                                DOX,
                                your next great learning adventure is just a click away."
                        image="assets/discover.jpg"
                        isSwapped={false}/>

                    <SplitGrid
                        heading="Connect with Mentors"
                        text="Find mentors and become a mentor yourself. DOX connects you with experienced individuals who
                                can
                                guide you on your learning journey."
                        image="assets/mentor.jpg"
                        isSwapped={true}/>

                    <SplitGrid
                        heading="Grow Your Skills"
                        text="Whether you're learning something new or deepening existing knowledge, DOX provides the
                                resources
                                and community support to help you grow. Join us and start your learning journey today."
                        image="assets/grow.jpg"
                        isSwapped={false}/>
                </div>
                <br/>
            </div>
        </div>
)
    ;
}

export default Home;
