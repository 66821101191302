import React from 'react';

function NotFound() {
    return (
        <h1>
            404 Error
        </h1>
    );
}

export default NotFound;