import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./navigation/Layout";
import Home from "./navigation/Home";
import NotFound from "./navigation/NotFound";
import JobAdvertisement from "./navigation/JobAdvertisement";
import About from "./navigation/About";
import "./App.css";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout/>} >
              <Route index element={<Home/>}/>
              <Route path="/jobs" element={<JobAdvertisement/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="*" element={<NotFound/>}/>
          </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
