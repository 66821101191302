import React from 'react';
import PropTypes from 'prop-types';
import './styles/SplitGrid.css'; // Import the CSS file

SplitGrid.propTypes = {
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    isSwapped: PropTypes.bool.isRequired,
};

function SplitGrid({ heading, text, image, isSwapped }:any) {
    return (
        <div className="split-grid-container">
            <div className="leftDiv">
                <img className="img-split" src={image} alt="image not found"/>
            </div>
            <div className="rightDiv">
                <div className="heading-split">{heading}</div>
                <p className="paragraph">{text}</p>
            </div>
        </div>
    );
}

export default SplitGrid;